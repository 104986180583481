import "./App.css";

import { useRef } from "react";

import Epk from "./epk";
// import { fadeIn } from "./hooks/useAnimation";
import { inject } from '@vercel/analytics';

import ReactPlayer from "react-player"

import mfd_title from "./mfd/mfd_title.png";
import mfd_tracks from "./mfd/mfd_tracks.png";
// import Player from "./webamp";

import download_icon from "./download_icon.png";

inject();

function App() {
  const mainRef = useRef();


  // useEffect(() => {
  //   fadeIn(mainRef.current, 0.005);
  // }, []);

  return (
    <main className="font-mavenRegular fade-in main text-center items-center justify-center" ref={mainRef}>
      {/* <div className="flex flex-col justify-center text-center gap-20 pt-16">
        <div className="text-3xl sticky  z-50 text-white">
          badkuzu
        </div>
        <div className="text-3xl absolute text-center text-white">
          <div className="opacity-60 ">badkuzu</div>
        </div>
      </div> */}
      <div className="sticky top-0 p-14 font-mavenSemiBold text-white text-3xl">
        badkuzu
      </div>
      <div className="absolute top-0 w-full opacity-50 p-14 font-mavenSemiBold text-white text-3xl">
        badkuzu
      </div>
      {/* webamp here */}
      {/* <Player /> */}
      <div className="flex flex-col gap-5 items-center" style={{ height: '75vh' }}></div>
      {/* mobile version */}
      <div className="md:hidden flex flex-col gap-2 items-center pt-10 w-full">
        <ReactPlayer
          url="https://api.soundcloud.com/playlists/1917898859&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=true"
          width="80%"
          height="500px"
        />
      </div>
      {/* desktop version */}
      <div className=" hidden md:flex flex-col gap-2 items-center pt-10 w-full">
        <ReactPlayer
          url="https://api.soundcloud.com/playlists/1917898859&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=true"
        />
      </div>
      {/* <br /> <br /><br /> <br /><br /> <br />
      <div className="flex flex-col gap-5 items-center">
        <img src="media/mfd_cover.png" className="w-2/3 md:w-1/3" />
      </div>
      <div className="flex flex-col gap-2 items-center pt-10">
        <a href="https://www.dropbox.com/scl/fo/jddabo3u6dbx9oezhdxto/AHrclxzmZ_sJI-wgCtb_Iao?rlkey=0nhtbqkh851eo4gy3jqumu0dk&st=gogcbpex&dl=0" target="_blank" style={{ filter: "hue-rotate(330deg)" }} className="w-1/2 lg:w-1/6 p-2 pb-4">
          <img src={download_icon} />
          <div className="text-white text-2xl font-extrabold">MUSIC FOR DANCING EP <br /> <div className="text-xl">FREE HI-RES DOWNLOAD</div></div>
        </a>
      </div> */}
      <br /><br /><br />
      <Epk />
      <br /><br /><br />
      <div className="text-white">©℗ badkuzu</div>
      <div className="background" />
      <br />
      <div style={{ height: "100px", position: "relative" }}></div>
    </main>
  );
}

export default App;
